<template>
  <referral />
</template>

<script>
export default {
  components: {
    referral: require("./Referral.vue").default,
  },
  data() {
    return {
      // logo: require('@/assets/logo.svg')
    };
  }
};
</script>